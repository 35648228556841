import React, { useState } from "react";
// import DoubleImg from "./doubleImg";
// import SingleImg from "./singleImg";
// import PortableText from "./portableText";
// import SingleImg2 from "./singleImg2";
// import DoubleImg2 from "./doubleImgV";
import MultiGallery from "./MultiGallery";
import MultiVersion from "./MultiVersion";


const WorldlyVersion = ({gallery, display}) => {

// console.log({gallery});

const Content = {gallery}.gallery;

const [isIndex, setIsIndex] = useState(0);

let maxIndex = Content.length;
// console.log(maxIndex);

let newMaxIndex = maxIndex - 1;

// console.log(newMaxIndex);

const Add = (isIndex, newMaxIndex) => {
  if(isIndex === newMaxIndex) {
    setIsIndex( isIndex = 0 );
  } else if (isIndex === maxIndex){
    setIsIndex( isIndex = newMaxIndex );
  } 
  else {
    setIsIndex( isIndex + 1 );
  };
};

const Less = (isIndex, newMaxIndex) => {
  let maxIndex = Content.length;
  if(isIndex === 0) {
    setIsIndex( isIndex = maxIndex - 1 );
  } else if(isIndex === newMaxIndex){
    setIsIndex( newMaxIndex - 1 );
  }
  else {
    setIsIndex( isIndex - 1 );
  };
};




const multiGallery2 = (Content) => {
  let el = null; 
if(Content !== null) {
if (display === "Vertical"){
  el = <>
      <MultiGallery gallery={Content} />
      </>
} else if (display === "Carrousel"){
el = <>
      <MultiVersion gallery={Content}/>
      </>
} else {
  el= null;
};
} else {
el = null;
}
  return el;
}

//switch ends
return (
    <>
    <div>
    {multiGallery2(Content)}
    </div>

    </>
  )
}

 
export default WorldlyVersion;