import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const DoubleImg2 = ({Imgkey ,gatsImg, gatsImgKey, gatsImgAlt }) => {
    return ( 
        <div className="oneOfTwo2" key={Imgkey}>
            <div className="oftWrap2">
                <GatsbyImage loading="eager" objectFit="contain" image={gatsImg} key={gatsImgKey} alt={gatsImgAlt} />
            </div>
          </div>
     );
}
 
export default DoubleImg2;