import { graphql } from "gatsby";
import BlogPost from "../components/blog-post";
import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import Container from "../components/layouts/container";
import Seo from "../components/seo";
import { toPlainText } from "../lib/helpers";
import Miniview from "../components/viewport";
import LayoutWork from "../components/layouts/layoutWork";

export const query = graphql`
query elWork($id: String!) {
  post: sanityPost(id: {eq: $id}) {
    id
    title
    slug {
      current
    }
    _rawExcerpt(resolveReferences: {maxDepth: 5})
    _rawBody(resolveReferences: {maxDepth: 5})
    thumbnail {
      ... on SanityCloudvideo {
        _key
        _type
        title
        altTitle
        url
      }
      ... on SanityMainImage {
        _key
        _type
        alt
        caption
        asset {
          title
          _key
          _id
          altText
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          id
          url
        }
      }
    }
    gallery {
      display
      title
      _key
      _type
      images {
        _key
        _type
        alt
        asset {
          _id
          _key
          altText
          gatsbyImageData(layout: CONSTRAINED, height: 1250, placeholder: BLURRED)
          url
          title
          id
        }
      }
    }
    pageBuilderTwo {
      ... on SanityVideo {
        _key
        _type
        url
        title
        _rawBodyPortableText
      }
      ... on SanitySingleGallery {
        _key
        _type
        _rawBodyPortableText
        title
        display
        bodyPortableText {
          _key
        }
        images {
          alt
          _key
          asset {
            gatsbyImageData
            description
            _id
            _key
            altText
            url
            title
            id
          }
        }
      }
      ... on SanityPortableTextContainerThree {
        _key
        _type
        title
        _rawBodyPortableText
      }
      ... on SanityPortableTextContainerFive {
        _key
        _type
        _rawBodyPortableText
        title
        display
      }
      ... on SanityPortableTextContainer {
        _key
        _type
        _rawBodyPortableText
        title
      }
    }
    displayMulti
    multiGallery {
      ... on SanityDoubleImage {
        _key
        _type
        mainImage {
          _key
          _type
          asset {
            _id
            _key
            gatsbyImageData(layout: CONSTRAINED, height: 1250, placeholder: BLURRED)
            id
          }
        }
        altFirst
        altSecond
        secondImage {
          _type
          _key
          asset {
            id
            gatsbyImageData(layout: CONSTRAINED, height: 1250, placeholder: BLURRED)
            _key
            _id
          }
        }
        name
      }
      ... on SanitySingleImage {
        _key
        _type
        caption
        alt
        asset {
          url
          title
          id
          gatsbyImageData(layout: CONSTRAINED, height: 1250, placeholder: BLURRED)
          altText
          _type
          _id
          _key
        }
      }
      ... on SanityImageText {
        _key
        _type
        name
        nameTitle
        display
        alt
        _rawBodyPortableText
        mainImage {
          _key
          _type
          asset {
            _id
            _key
            gatsbyImageData(layout: CONSTRAINED, height: 1250, placeholder: BLURRED)
            id
            url
            title
          }
        }
        nameTitle
      }
    }
  }
  view: allSanityPost(filter: {slug: {current: {ne: null}}}) {
    works: nodes {
      id
      title
      _rawExcerpt
      slug {
        current
      }
      display
      thumbnail {
        ... on SanityCloudvideo {
          _key
          _type
          url
          title
          altTitle
        }
        ... on SanityMainImage {
          _key
          _type
          alt
          caption
          asset {
            _key
            _id
            altText
            url
            title
            gatsbyImageData(layout: CONSTRAINED, height: 2000, placeholder: BLURRED)
          }
        }
      }
    }
  }
}
`;
const WorkPost = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const Views = data.view.works;

  return (
    <LayoutWork>
      {errors && <Seo title="GraphQL Error" />}
      {post && (
        <Seo
          title={post.title || "Untitled"}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {post && <BlogPost {...post} />}
      <div className="controlWrapper">
      <hr className="line"/>
      <div className="viewPortWrap">
      <Miniview what={Views} />
      </div>
      </div>
    </LayoutWork>
  );
};

export default WorkPost;