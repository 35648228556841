import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const SingleImg = ({ sImgKey, sImgGats,sImgGatsId, sImgGatAlt, sImgCap }) => {
    return ( 
        <div className="sImageWrap" key={sImgKey}>
        <div className="sImg">
          <GatsbyImage loading="eager" objectFit="contain" image={sImgGats} key={sImgGatsId} alt={sImgGatAlt} />
          </div>
          { sImgCap && 
          <p className="captionAlt">{sImgCap}</p>
          }
      </div>
     );
}
 
export default SingleImg;