import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Back from "./back";

const Next = ({ currentUrl }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      sanityHome {
        _id
        topNavigation {
          id
          title
          slug {
            current
          }
        }
      }
    }
  `);

  const topNavigation = data.sanityHome.topNavigation;

  const currentIndex = topNavigation.findIndex(
    (nav) => nav.slug.current === currentUrl
  );

  const previousItem =
    currentIndex > 0 ? topNavigation[currentIndex - 1] : null;
  const nextItem =
    currentIndex < topNavigation.length - 1
      ? topNavigation[currentIndex + 1]
      : null;

  return (
    <div className="flex flex-row justify-between w-full">
      {previousItem ? (
        <Link className="pcEach" to={`/${previousItem.slug.current}`}>
          Previous: {previousItem.title}
        </Link>
      ) : (
        <Back />
      )}
      {nextItem && (
        <Link className="pcEach" to={`/${nextItem.slug.current}`}>
          Next: {nextItem.title}
        </Link>
      )}
    </div>
  );
};

export default Next;
