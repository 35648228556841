import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const SingleImg2 = ({ sImgKey, sImgGats,sImgGatsId, sImgGatAlt, sImgCap }) => {
    return ( 
        <div className="sImageWrap2" key={sImgKey}>
        <div className="sImg2">
          <GatsbyImage loading="eager" objectFit="contain" image={sImgGats} key={sImgGatsId} alt={sImgGatAlt} />
          { sImgCap && 
          <p className="captionAlt2">{sImgCap}</p>
          } 
        </div>
      </div>
     );
}
 
export default SingleImg2;