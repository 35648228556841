import React from "react";
import DoubleImg from "./doubleImg";
import SingleImg from "./singleImg";
import PortableText from "./portableText";
// import RichText from "./serializers";


const MultiGallery = ({gallery}) => {

  console.log({gallery});

const Content = {gallery}.gallery;

const multiGallery = (Content) 
.filter(y => !y.disabled)
.filter(y => !y._key != null)
//map begins 
.map((y) => { 
let el = null;
//switch begins
switch (y._type) {
  case "singleImage":
    el = 
    <div className="SingleWrap">
    <SingleImg
      sImgKey={y._key}
      sImgGats={y.asset.gatsbyImageData}
      sImgGatsId={y.asset._id}
      sImgGatAlt={y.alt}
      sImgCap={y.caption}
      />
      </div>
    break;
  case "doubleImage":
    el =  <div className="dImageWrap" key={y._key}>
          <DoubleImg
            Imgkey={y.mainImage.asset._id}
            gatsImg={y.mainImage.asset.gatsbyImageData}
            gatsImgKey={y.mainImage.asset._id}
            gatsImgAlt={y.altFirst}
            />
            <DoubleImg
            Imgkey={y.secondImage.asset._id}
            gatsImg={y.secondImage.asset.gatsbyImageData} 
            gatsImgKey={y.secondImage.asset._id}
            gatsImgAlt={y.altSecond}
            />
          </div>
    break;
    case "imageText":
     switch(y.display) {
      case "right":
        el=<>
          <div className="iTWrap" key={y._key}>
          <DoubleImg
            Imgkey={y.mainImage.asset._id}
            gatsImg={y.mainImage.asset.gatsbyImageData}
            gatsImgKey={y.mainImage.asset._id}
            gatsImgAlt={y.alt}
            />
            <div className="moduleText" key={y._rawBodyPortableText._key}>
            <PortableText blocks={y._rawBodyPortableText} />
            </div>
          </div>
            </>
      break;
      case "left":
          el= 
          <>
          <div className="iTWrap" key={y._key}>
            <div className="moduleText" key={y._rawBodyPortableText._key}>
            <div className="layText">
            <PortableText blocks={y._rawBodyPortableText} />
            </div>
            </div>
            <DoubleImg
            Imgkey={y.mainImage.asset._id}
            gatsImg={y.mainImage.asset.gatsbyImageData}
            gatsImgKey={y.mainImage.asset._id}
            gatsImgAlt={y.alt}
            />
          </div>
          </>
      break;
      default:
        el = null;
      break;
     }      
    break;
    default:
    el = null;
}
//switch ends
return el;
//map ends 
});



return (
    <>
      <div className="multiWrap">
         <h1>{multiGallery}</h1>
      </div>
    </>
  )
}

 
export default MultiGallery;