import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const ImageText = ({Imgkey ,gatsImg, gatsImgKey, gatsImgAlt }) => {
    return ( 
            <div key={Imgkey} className="oftWrap3">
                <GatsbyImage loading="eager" objectFit="contain" image={gatsImg} key={gatsImgKey} alt={gatsImgAlt} />
            </div>
     );
}
 
export default ImageText;