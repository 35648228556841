import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import CloudVideo from "./layouts/cloudvideo";
import { InView } from "react-intersection-observer";
import { imageUrlFor } from "../lib/image-url";
import Chance from "chance";

const chance = new Chance();

const Miniview = ({ what }) => {
  const [isBlur, setIsBlur] = useState(false);
  const [newViews, setNewViews] = useState([]);

  useEffect(() => {
    const views = chance.pickset(what, 3);
    setNewViews(views);
  }, [what]);

  const makeBlur = () => {
    setIsBlur(true);
  };

  const makeClear = () => {
    setIsBlur(false);
  };

  if (newViews.length === 0) {
    return null;
  }

  return (
    <div className="h2Wrap">
      <div className="h2rightV" key={newViews[0].id}>
        <Link to={`/${newViews[0].slug.current}`}>
          {newViews[0].thumbnail.map((view) => {
            let el = null;
            switch (view._type) {
              case "mainImage":
                el = (
                  <InView key={view.asset._key}>
                    {({ inView, ref, entry }) => (
                      <div ref={ref} className="h2Mod group" 
                      onMouseEnter={makeBlur}
                      onMouseLeave={makeClear}>
                        <img
                          loading="eager"
                          className={
                            isBlur
                              ? "postBlur object-contain"
                              : "NopostBlur object-contain"
                          }
                          src={imageUrlFor(view.asset.url).auto("format").url()}
                          alt={view.alt}
                        />
                        {inView ? (
                          <h1 className="indexWorkTitle animate-appearTitle">
                            {newViews[0].title}
                          </h1>
                        ) : null}
                      </div>
                    )}
                  </InView>
                );
                break;
              case "cloudvideo":
                el = (
                  <InView key={view._key}>
                    {({ inView, ref, entry }) => (
                      <div className="h2Mod group" 
                      onMouseEnter={makeBlur}
                      onMouseLeave={makeClear}>
                        <div
                          ref={ref}
                          className={
                            isBlur
                              ? "postBlur object-contain"
                              : "NopostBlur object-contain"
                          }
                        >
                          <CloudVideo alt={view.alt} cloudVideoUrl={view.url} />
                          {inView ? (
                            <h1 className="indexWorkTitle animate-appearTitle">
                              {newViews[0].title}
                            </h1>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </InView>
                );
                break;
              default:
                el = null;
            }
            return el;
          })}
        </Link>
      </div>
      <div className="h2middle" key={newViews[1].id}>
        <Link to={`/${newViews[1].slug.current}`}>
          {newViews[1].thumbnail.map((view) => {
            let el = null;
            switch (view._type) {
              case "mainImage":
                el = (
                  <InView key={view.asset._key}>
                    {({ inView, ref, entry }) => (
                      <div ref={ref} className="h2Mod group" 
                      onMouseEnter={makeBlur}
                      onMouseLeave={makeClear}>
                        <img
                          className={
                            isBlur
                              ? "postBlur object-contain"
                              : "NopostBlur object-contain"
                          }
                          loading="eager"
                          src={imageUrlFor(view.asset.url).auto("format").url()}
                          alt={view.alt}
                        />
                        {inView ? (
                          <h1 className="indexWorkTitle animate-appearTitle">
                            {newViews[1].title}
                          </h1>
                        ) : null}
                      </div>
                    )}
                  </InView>
                );
                break;
              case "cloudvideo":
                el = (
                  <InView key={view._key}>
                    {({ inView, ref, entry }) => (
                      <div ref={ref} className="h2Mod group" 
                      onMouseEnter={makeBlur}
                      onMouseLeave={makeClear}>
                        <div
                          className={
                            isBlur
                              ? "postBlur object-contain"
                              : "NopostBlur object-contain"
                          }
                        >
                          <CloudVideo alt={view.alt} cloudVideoUrl={view.url} />
                          {inView ? (
                            <h1 className="indexWorkTitle animate-appearTitle">
                              {newViews[1].title}
                            </h1>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </InView>
                );
                break;
              default:
                el = null;
            }
            return el;
          })}
        </Link>
      </div>
      <div className="h2full addView" key={newViews[2].id}>
        <Link to={`/${newViews[2].slug.current}`}>
          {newViews[2].thumbnail.map((view) => {
            let el = null;
            switch (view._type) {
              case "mainImage":
                el = (
                  <InView key={view.asset._key}>
                    {({ inView, ref, entry }) => (
                      <div ref={ref} className="h2Mod group" 
                      onMouseEnter={makeBlur}
                      onMouseLeave={makeClear}>
                        <img
                          loading="eager"
                          className={
                            isBlur
                              ? "postBlur object-contain"
                              : "NopostBlur object-contain"
                          }
                          src={imageUrlFor(view.asset.url).auto("format").url()}
                          alt={view.alt}
                        />
                        {inView ? (
                          <h1 className="indexWorkTitle animate-appearTitle">
                            {newViews[2].title}
                          </h1>
                        ) : null}
                      </div>
                    )}
                  </InView>
                );
                break;
              case "cloudvideo":
                el = (
                  <InView key={view._key}>
                    {({ inView, ref, entry }) => (
                      <div
                        className="h2Mod group"
                        onMouseEnter={makeBlur}
                        onMouseLeave={makeClear}
                      >
                        <div
                          ref={ref}
                          className={
                            isBlur
                              ? "postBlur object-contain"
                              : "NopostBlur object-contain"
                          }
                        >
                          <CloudVideo alt={view.alt} cloudVideoUrl={view.url} />
                          {inView ? (
                            <h1 className="indexWorkTitle animate-appearTitle">
                              {newViews[2].title}
                            </h1>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </InView>
                );
                break;
              default:
                el = null;
            }
            return el;
          })}
        </Link>
      </div>
    </div>
  );
};

export default Miniview;

// import React, { useState } from "react";
// import { Link } from "gatsby";
// import CloudVideo from "./layouts/cloudvideo";
// import { InView } from "react-intersection-observer";
// import { imageUrlFor } from "../lib/image-url";

// const Miniview = ({ what }) => {
//   const [isBlur, setIsBlur] = useState(false);

//   const makeBlur = () => {
//     setIsBlur(!isBlur);
//   };
//   const makeClear = () => {
//     setIsBlur(!isBlur);
//   };

//   const All = { what };

//   var Chance = require("chance");
//   var chance = new Chance();
//   const newViews = chance.pickset(what, 3);

//   return (
//     <>
//       <div className="h2Wrap">
//         <div className="h2rightV" key={newViews[0].id}>
//           <Link to={`/${newViews[0].slug.current}`}>
//             {newViews[0].thumbnail.map((view) => {
//               let el = null;
//               switch (view._type) {
//                 case "mainImage":
//                   el = (
//                     <>
//                       <InView>
//                         {({ inView, ref, entry }) => (
//                           <div ref={ref} className="h2Mod group">
//                             {/* <img key={view.asset._key} src={view.asset.url} alt={view.alt} />  */}
//                             <img
//                               key={view.asset._key}
//                               loading="eager"
//                               onMouseEnter={makeBlur}
//                               onMouseLeave={makeClear}
//                               className={
//                                 isBlur
//                                   ? "postBlur object-contain"
//                                   : "NopostBlur object-contain"
//                               }
//                               src={imageUrlFor(view.asset.url)
//                                 .auto("format")
//                                 .url()}
//                               alt={view.alt}
//                             />

//                             {inView ? (
//                               <h1 className="indexWorkTitle animate-appearTitle">
//                                 {newViews[0].title}
//                               </h1>
//                             ) : null}
//                           </div>
//                         )}
//                       </InView>
//                     </>
//                   );
//                   break;
//                 case "cloudvideo":
//                   el = (
//                     <InView>
//                       {({ inView, ref, entry }) => (
//                         <div className="h2Mod group">
//                           <div
//                             ref={ref}
//                             key={view._key}
//                             onMouseEnter={makeBlur}
//                             onMouseLeave={makeClear}
//                             className={
//                               isBlur
//                                 ? "postBlur object-contain"
//                                 : "NopostBlur object-contain"
//                             }
//                           >
//                             <CloudVideo
//                               alt={view.alt}
//                               cloudVideoUrl={view.url}
//                             />
//                             {inView ? (
//                               <h1 className="indexWorkTitle animate-appearTitle">
//                                 {newViews[0].title}
//                               </h1>
//                             ) : null}
//                           </div>
//                         </div>
//                       )}
//                     </InView>
//                   );
//                   break;
//                 default:
//                   el = null;
//               }
//               return el;
//             })}
//           </Link>
//         </div>
//         <div className="h2middle" key={newViews[1].id}>
//           <Link to={`/${newViews[1].slug.current}`}>
//             {newViews[1].thumbnail.map((view) => {
//               let el = null;
//               switch (view._type) {
//                 case "mainImage":
//                   el = (
//                     <>
//                       <InView>
//                         {({ inView, ref, entry }) => (
//                           <div ref={ref} className="h2Mod group">
//                             {/* <img className='NopostBlur'  key={view.asset._key} src={view.asset.url} alt={view.alt} />  */}
//                             <img
//                               key={view.asset._key}
//                               onMouseEnter={makeBlur}
//                               onMouseLeave={makeClear}
//                               className={
//                                 isBlur
//                                   ? "postBlur object-contain"
//                                   : "NopostBlur object-contain"
//                               }
//                               loading="eager"
//                               src={imageUrlFor(view.asset.url)
//                                 .auto("format")
//                                 .url()}
//                               alt={view.alt}
//                             />
//                             {inView ? (
//                               <h1 className="indexWorkTitle  animate-appearTitle">
//                                 {newViews[1].title}
//                               </h1>
//                             ) : null}
//                           </div>
//                         )}
//                       </InView>
//                     </>
//                   );
//                   break;
//                 case "cloudvideo":
//                   el = (
//                     <InView>
//                       {({ inView, ref, entry }) => (
//                         <div ref={ref} className="h2Mod group">
//                           <div
//                             onMouseEnter={makeBlur}
//                             onMouseLeave={makeClear}
//                             className={
//                               isBlur
//                                 ? "postBlur object-contain"
//                                 : "NopostBlur object-contain"
//                             }
//                             key={view._key}
//                           >
//                             <CloudVideo
//                               alt={view.alt}
//                               cloudVideoUrl={view.url}
//                             />
//                             {inView ? (
//                               <h1 className="indexWorkTitle  animate-appearTitle">
//                                 {newViews[1].title}
//                               </h1>
//                             ) : null}
//                           </div>
//                         </div>
//                       )}
//                     </InView>
//                   );
//                   break;
//                 default:
//                   el = null;
//               }
//               return el;
//             })}
//           </Link>
//         </div>
//         <div className="h2full addView" key={newViews[2].id}>
//           <Link to={`/${newViews[2].slug.current}`}>
//             {newViews[2].thumbnail.map((view) => {
//               let el = null;
//               switch (view._type) {
//                 case "mainImage":
//                   el = (
//                     <>
//                       <InView>
//                         {({ inView, ref, entry }) => (
//                           <div ref={ref} className="h2Mod group">
//                             {/* <img className='NopostBlur'  key={view.asset._key} src={view.asset.url} alt={view.alt} />  */}
//                             <img
//                               key={view.asset._key}
//                               loading="eager"
//                               onMouseEnter={makeBlur}
//                               onMouseLeave={makeClear}
//                               className={
//                                 isBlur
//                                   ? "postBlur object-contain"
//                                   : "NopostBlur object-contain"
//                               }
//                               src={imageUrlFor(view.asset.url)
//                                 .auto("format")
//                                 .url()}
//                               alt={view.alt}
//                             />
//                             {inView ? (
//                               <h1 className="indexWorkTitle  animate-appearTitle">
//                                 {newViews[2].title}
//                               </h1>
//                             ) : null}
//                           </div>
//                         )}
//                       </InView>
//                     </>
//                   );
//                   break;
//                 case "cloudvideo":
//                   el = (
//                     <InView>
//                       {({ inView, ref, entry }) => (
//                         <div className="h2Mod group">
//                           <div
//                             ref={ref}
//                             onMouseEnter={makeBlur}
//                             onMouseLeave={makeClear}
//                             className={
//                               isBlur
//                                 ? "postBlur object-contain"
//                                 : "NopostBlur object-contain"
//                             }
//                             key={view._key}
//                           >
//                             <CloudVideo
//                               alt={view.alt}
//                               cloudVideoUrl={view.url}
//                             />
//                             {inView ? (
//                               <h1 className="indexWorkTitle  animate-appearTitle">
//                                 {newViews[2].title}
//                               </h1>
//                             ) : null}
//                           </div>
//                         </div>
//                       )}
//                     </InView>
//                   );
//                   break;
//                 default:
//                   el = null;
//               }
//               return el;
//             })}
//           </Link>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Miniview;
