import React, { useState } from "react";
import PortableText from "./portableText";
import SingleImg2 from "./singleImg2";
import DoubleImg2 from "./doubleImgV";
import ImageText from "./ImgforText";


const MultiVersion = ({gallery, action}) => {

// console.log({gallery});

const Content = {gallery}.gallery;

const [isIndex, setIsIndex] = useState(0);

let maxIndex = Content.length;
// console.log(maxIndex);

let newMaxIndex = maxIndex - 1;

// console.log(newMaxIndex);

const Add = (isIndex, newMaxIndex) => {
  if(isIndex === newMaxIndex) {
    setIsIndex( isIndex = 0 );
  } else if (isIndex === maxIndex){
    setIsIndex( isIndex = newMaxIndex );
  } 
  else {
    setIsIndex( isIndex + 1 );
  };
};

const Less = (isIndex, newMaxIndex) => {
  let maxIndex = Content.length;
  if(isIndex === 0) {
    setIsIndex( isIndex = maxIndex - 1 );
  } else if(isIndex === newMaxIndex){
    setIsIndex( newMaxIndex - 1 );
  }
  else {
    setIsIndex( isIndex - 1 );
  };
};




const multiGallery2 = (Content) => {
  let el = null; 
if(Content !== null) {
  switch(Content[isIndex]._type){
    case "singleImage":
    el = 
    <SingleImg2
      sImgKey={Content[isIndex]._key}
      sImgGats={Content[isIndex].asset.gatsbyImageData}
      sImgGatsId={Content[isIndex].asset._id}
      sImgGatAlt={Content[isIndex].alt}
      sImgCap={Content[isIndex].caption}
      />
    break;
  case "doubleImage":
    el =  <div className="dImageWrap2" key={Content[isIndex]._key}>
          <DoubleImg2
            Imgkey={Content[isIndex].mainImage.asset._id}
            gatsImg={Content[isIndex].mainImage.asset.gatsbyImageData}
            gatsImgKey={Content[isIndex].mainImage.asset._id}
            gatsImgAlt={Content[isIndex].altFirst}
            />
            <DoubleImg2
            Imgkey={Content[isIndex].secondImage.asset._id}
            gatsImg={Content[isIndex].secondImage.asset.gatsbyImageData} 
            gatsImgKey={Content[isIndex].secondImage.asset._id}
            gatsImgAlt={Content[isIndex].altSecond}
            />
          </div>
    break;
    case "imageText":
     switch(Content[isIndex].display) {
      case "right":
        el=<>
          <div className="RigiTWrap2" key={Content[isIndex]._key}>   
          <div className="oneOfTwo3">
            <ImageText
              Imgkey={Content[isIndex].mainImage.asset._id}
              gatsImg={Content[isIndex].mainImage.asset.gatsbyImageData}
              gatsImgKey={Content[isIndex].mainImage.asset._id}
              gatsImgAlt={Content[isIndex].alt}
              />
            <p className="readBio"><a href="#read">Read bio</a></p>

          </div>
          <div className="moduleText2" key={Content[isIndex]._rawBodyPortableText._key}>
              <div id="read" className="layText2">
              <h3 className="nameTitle">{Content[isIndex].nameTitle}</h3>
              <PortableText blocks={Content[isIndex]._rawBodyPortableText} />
              </div>
          </div>
          </div>
            </>
      break;
      case "left":
          el= 
          <>
          <div className="LefiTWrap2">
            <div id="read" className="moduleText2" key={Content[isIndex]._rawBodyPortableText._key}>
            <div className="layText2">
            <h3 className="nameTitle">{Content[isIndex].nameTitle}</h3>
            <PortableText blocks={Content[isIndex]._rawBodyPortableText} />
            </div>
            </div>
            <div className="oneOfTwo3">
           <ImageText
            Imgkey={Content[isIndex].mainImage.asset._id}
            gatsImg={Content[isIndex].mainImage.asset.gatsbyImageData}
            gatsImgKey={Content[isIndex].mainImage.asset._id}
            gatsImgAlt={Content[isIndex].alt}
            />
            <p className="readBio"><a href="#read">Read bio</a></p>
            </div>
            </div>
          </>
      break;
      default:
        el = null;
      break;
     }      
    break;
    default:
    el = null;
    break;
};
} else {
el = null;
}
  return el;
}

//switch ends
return (
    <>
    <div>
    {multiGallery2(Content)}
    <div className="PagegallControls"> 
        <a href="#backTop" className="viewCTA">
        <span className="oneOf">{isIndex + 1} of {Content.length} </span>
        </a>
        <div className="multiControls">
        <button type="button" className="viewCTA" onClick={()=>{Less(isIndex,newMaxIndex)}}><a href="#top">Prev</a></button>
        <button type="button" className="viewCTA" onClick={()=>{Add(isIndex,newMaxIndex)}}><a href="#top">Next</a></button>
        </div>
        </div>
    </div>
    </>
  )
}

 
export default MultiVersion;