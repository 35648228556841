import PortableText from "./portableText";
import React from "react";
import Container from "./layouts/container";
import Video from "./layouts/video";
import Back from "./navigation/back";
import Next from "./navigation/next";
import PostGallery from "./postGallery";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
// import MultiGallery from "./MultiGallery";
// import MultiVersion from "./WorldlyVersion";
import WorldlyVersion from "./WorldlyVersion";

function BlogPost(props) {
  const {
    _rawBody,
    // pageBuilder,
    pageBuilderTwo,
    // video,
    slug,
    title,
    gallery,
    displayMulti,
    multiGallery,
  } = props;

  // console.log(`${slug.current}`);

  // console.log(displayMulti);
  // console.log(multiGallery);

  let Test = "";
  if (gallery !== null) {
    Test = gallery.images.length;
  }

  const BuilderTwo = pageBuilderTwo
    .filter((c) => !c.disabled)
    .map((c) => {
      let el = null;
      switch (c._type) {
        case "portableTextContainer":
          el = (
            <div className="postDescription" key={c._key}>
              <PortableText blocks={c._rawBodyPortableText} />
            </div>
          );
          break;
        case "video":
          el = (
            <div className="videoWrapper">
              <Video key={c._key} videoSrcURL={c.url} videoTitle={c.title} />
              {c._rawBodyPortableText && (
                <div className="videoDesc" key={c._rawBodyPortableText._key}>
                  <PortableText blocks={c._rawBodyPortableText} />
                </div>
              )}
            </div>
          );
          break;
        case "portableTextContainerThree":
          el = (
            <div className="titleboxPages" key={c._key}>
              <h3 className="titleBox">{c.title}</h3>
              <div className="titlecopyBox">
                <PortableText blocks={c._rawBodyPortableText} />
              </div>
            </div>
          );
          break;
        case "video":
          el = (
            <div className="marginVideo" key={c._key}>
              <h3 className="pageVideoTitle">{c.title}</h3>
              <div className="videoWrapper">
                <Video videoSrcURL={c.url} videoTitle={c.title} />
              </div>
              {c._rawBodyPortableText && (
                <p className="pagesVideoDesc">
                  <PortableText blocks={c._rawBodyPortableText} />
                </p>
              )}
            </div>
          );
          break;
        case "portableTextContainerFive":
          if (c.display === "center") {
            el = (
              <div className="boxPages" key={c._key}>
                <div className="centerCopyBox">
                  <PortableText blocks={c._rawBodyPortableText} />
                </div>
              </div>
            );
          } else {
            el = (
              <div className="boxPages" key={c._key}>
                <div className="copyBox">
                  <PortableText blocks={c._rawBodyPortableText} />
                </div>
              </div>
            );
          }
          break;
        case "singleGallery":
          if (c.display === "right") {
            el = (
              <div key={c._key} className="galleryTextWrap">
                <div className="singleGalleryWrap">
                  <GatsbyImage
                    image={getImage(c.images[0].asset.gatsbyImageData)}
                    key={c.images[0]._key}
                    alt={c.images[0].alt}
                  />
                </div>
                <div className="copySideBox" key={c.bodyPortableText._key}>
                  <PortableText blocks={c._rawBodyPortableText} />
                </div>
              </div>
            );
          } else {
            el = (
              <div key={c._key} className="galleryTextWrap">
                <div className="copySideBox" key={c.bodyPortableText._key}>
                  <PortableText blocks={c._rawBodyPortableText} />
                </div>
                <div className="singleGalleryWrap">
                  <GatsbyImage
                    image={getImage(c.images[0].asset.gatsbyImageData)}
                    key={c.images[0]._key}
                    alt={c.images[0].alt}
                  />
                </div>
              </div>
            );
          }
          break;
        default:
          el = null;
      }
      return el;
    });

  // console.log(multiGallery);
  // console.log(gallery);

  return (
    <>
      <div>
        <article className="postCont">
          <Container>
            {/* { pageBuilder && <div>{Builder}</div>} */}
            {gallery !== null && (
              <div>
                <PostGallery postGall={gallery} />
              </div>
            )}
            {multiGallery.length > 0 && (
              <div>
                <WorldlyVersion display={displayMulti} gallery={multiGallery} />
              </div>
            )}
            {pageBuilderTwo && <div>{BuilderTwo}</div>}
            {_rawBody && (
              <div className="postDescription">
                <PortableText blocks={_rawBody} />
              </div>
            )}
          </Container>
        </article>
        <h1 className="postTitle">{title}</h1>
        <div className="postControls">
          <Next currentUrl={slug.current} />
        </div>
      </div>
    </>
  );
}

export default BlogPost;
