import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const PostGallery = ({postGall}) => {

const [isCarrGrid, setIsCarrGrid] = useState(false);
const toggleCarrGrid = () => {
  setIsCarrGrid(!isCarrGrid);
  };

const [isGrid, setIsGrid] = useState(false);
const toggleGrid = () => {
setIsGrid(!isGrid);
};

const [isIndex, setIsIndex] = useState(0);

let maxIndex = postGall.images.length;

let newMaxIndex = maxIndex - 1;
const Add = (isIndex, newMaxIndex) => {
  if(isIndex === newMaxIndex) {
    setIsIndex( isIndex = 0 );
  } else if (isIndex === maxIndex){
    setIsIndex( isIndex = newMaxIndex );
  } 
  else {
    setIsIndex( isIndex + 1 );
  };
};
const Less = (isIndex, newMaxIndex) => {
  let maxIndex = postGall.images.length;
  if(isIndex === 0) {
    setIsIndex( isIndex = maxIndex - 1 );
  } else if(isIndex === newMaxIndex){
    setIsIndex( newMaxIndex - 1 );
  }
  else {
    setIsIndex( isIndex - 1 );
  };
};

const Zoom = ( index ) => {
  setIsIndex(index);
  setIsCarrGrid(!isCarrGrid);
};

const mainGallery = (postGall)
let gal = null;
if ( postGall !== null) {
if ( postGall.display === "Carrousel") {
  gal = 
  <>
    <div id="Top" className={isCarrGrid ? '' : 'carr-post-container'}>
    <div className={isCarrGrid ? 'gallGridWrap' : 'carr-post-wrapper'}>
      { !isCarrGrid && 
      <div className={isCarrGrid ? 'gallGrid': 'carr-post-content'}>
        <GatsbyImage loading="eager" objectFit="contain" className={isCarrGrid ? '' : 'carr-post-element'} key={postGall.images[isIndex]._key} image={postGall.images[isIndex].asset.gatsbyImageData} alt={`${postGall.images[isIndex].alt}`} />
        </div>
      }
{ isCarrGrid && 
      <div className={isCarrGrid ? 'gallGrid': 'carr-post-content'}>
      {postGall.images.map((i, index)=>
        <div role="button" onClick={()=>{Zoom(index)}}>
          <a href="#Top">
        <GatsbyImage 
        loading="lazy" index={index} key={i._key} image={i.asset.gatsbyImageData} alt={`${i.alt} image # ${index + 1}`} /></a>
        </div>
        )}
      </div>
      }
    </div>
    </div>
    { !isCarrGrid && 
    <>
    {/* <span className="viewIndex">{isIndex + 1} of {postGall.images.length} </span> */}
        <div className="PagegallControls"> 
        <a href="#backTop" className="viewCTA" onClick={toggleCarrGrid}>{isCarrGrid ? 'View Gallery' : 'View All'}</a>
        <div className="nextCont">
        <button type="button" className="viewCTA" onClick={()=>{Less(isIndex,newMaxIndex)}}>Prev </button><span className="g1oneOf">{isIndex + 1} of {postGall.images.length} </span><button type="button" className="viewCTA" onClick={()=>{Add(isIndex,newMaxIndex)}}>Next </button>
        </div>
        </div>
        </>
    }
    { isCarrGrid && 
    <div className="PagegallControls"> 
    <a href="#Top" className="viewCTA" onClick={toggleCarrGrid}>{isCarrGrid ? 'View Gallery' : 'View All'}</a>
    <div>  All {postGall.images.length}</div> </div>
}
</>
return gal;
} 
if ( postGall.display === "Vertical") {
  gal = 
  <>
  <div id="backTop" className={isGrid ? '' : 'gal-post-container'}>
  <div className={isGrid ? 'gallGridWrap' : 'gal-post-wrapper'}>
  <div className={isGrid ? 'gallGrid': 'gal-post-content'}>
  {postGall.images.map((i, index)=><GatsbyImage loading="eager" objectFit={isGrid ? 'cover' : 'contain'} className={isGrid ? '' : 'gal-post-element'} key={i._key} image={i.asset.gatsbyImageData} alt={`${i.alt} image # ${index + 1}`} />)}
  </div>
  </div>
  </div>
  <div className="PagegallControls">  
  <a href="#backTop" className="viewCTA" onClick={toggleGrid}>{isGrid ? 'View Gallery' : 'View All'}</a>
  <div> All {postGall.images.length}</div></div>
</>
return gal;
} 
}
  else {
    gal = null;
    return gal;
  };

return (
  <>
  <div id="top">
    {mainGallery}
  </div>
  </>
      )
  }
 
export default PostGallery;